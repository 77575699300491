/**
 * Módulo de rutas del módulo WwwModule. Contiene las definiciones de rutas
 * de la Página Oficial de la Dependencia del IEA (https://www.iea.gob.mx).
 */

// Componente principal del módulo...
import ModuleComponent from '../ModuleComponent.vue';

// Vistas (Views) del menú "Principal"...
import PrincipalView from '../views/PrincipalView.vue';

// Vistas (Views) del menú "¿Quiénes somos?"...
import SomosView from '../views/somos/SomosView.vue';
import ObjetivosView from '../views/somos/ObjetivosView.vue';
import HistoriaView from '../views/somos/HistoriaView.vue';
import OrganigramaView from '../views/somos/OrganigramaView.vue';

// Vistas (Views) del menú "Convocatorias y Becas"...
import BecasView from '../views/convocatorias-y-becas/BecasView.vue';
import HistoricoBecasView from '../views/convocatorias-y-becas/HistoricoBecasView.vue';
import ConvocatoriasView from '../views/convocatorias-y-becas/ConvocatoriasView.vue';
import ProcesosAdquisicionView from '../views/convocatorias-y-becas/ProcesosAdquisicionView.vue';

// Vistas (Views) del menú "Sistema educativo"...
import ParticipacionSocialView from '../views/sistema-educativo/ParticipacionSocialView.vue';
import EscuelaYFamiliaView from '../views/sistema-educativo/EscuelaYFamiliaView.vue';
import AcosoEscolarView from '../views/sistema-educativo/AcosoEscolarView.vue';
import EstrategiasAulaView from '../views/sistema-educativo/EstrategiasAulaView.vue';
import CarreraMagisterioView from '../views/sistema-educativo/CarreraMagisterioView.vue';
const TempLink = { template: "", created() { window.location.href = "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Proceso-de-Promoción-a-Categorías-con-Funciones-Directivas-o-de-Supervisión-en-Educación-Básica (Plazas Disponibles).pdf"; } }; // 31/07/2025

// Vistas (Views) del menú "Trámites y Servicios"...
import DirectorioTelefonicoView from '../views/tramites-y-servicios/DirectorioTelefonicoView.vue';
import ServicioSocialView from '../views/tramites-y-servicios/ServicioSocialView.vue';
import IncorporacionView from '../views/tramites-y-servicios/IncorporacionView.vue';

// Vistas (Views) del menú "Control interno"...
import SciiView from '../views/scii/SciiView.vue';
import ManualesView from '../views/scii/ManualesView.vue';
import NormatividadView from '../views/scii/NormatividadView.vue';
import CodigoEticaYConductaView from '../views/scii/CodigoEticaYConductaView.vue';
import ComisionDeSeguridadEHigieneView from '../views/scii/ComisionDeSeguridadEHigieneView.vue';
import LineamientosYPoliticasDeEscuelasView from '../views/scii/LineamientosYPoliticasDeEscuelasView.vue';

// Vistas (Views) del menú de "Transparencia"...
import InformesSRFTView from '../views/transparencia/InformesSRFTView.vue';
import ReportesSHCPView from '../views/transparencia/ReportesSHCPView.vue';
import InformesPAEView from '../views/transparencia/InformesPAEView.vue';
import SEDView from '../views/transparencia/SEDView.vue';
import TituloVLGCGView from '../views/transparencia/TituloVLGCGView.vue';
import IECRView from '../views/transparencia/IECRView.vue';
import EvaluacionesExternasView from '../views/transparencia/EvaluacionesExternasView.vue';
import MIRyFIDView from '../views/transparencia/MIRyFIDView.vue';
import IndicadoresFONEyConveniosFederalesView from '../views/transparencia/IndicadoresFONEyConveniosFederalesView.vue';
import AvisosDePrivacidadView from '../views/transparencia/AvisosDePrivacidadView.vue';

// Vistas (Views) de la seccion de "Enlaces relacionados"...
import EscalafonView from '../views/EscalafonView.vue';

// Vistas (Views) del menú de "Enlaces de interés"...
import StreamingView from '../views/StreamingView.vue';
import BibliotecaDigitalView from '../views/BibliotecaDigitalView.vue';
import VideotecaView from '../views/VideotecaView.vue';
//import DatosAbiertosView from '../views/DatosAbiertosView.vue';
import EducatelView from '../views/EducatelView.vue';

// Vista (View) de Noticia...
import NoticiaView from '../views/NoticiaView.vue';

const routes = [
  {
    path: '/',
    component: ModuleComponent,
    meta: { title: 'IEA | INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES' },
    children: [
      { path: '', component: PrincipalView, meta: { title: 'IEA | INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES' } },
      { path: 'somos', component: SomosView, meta: { title: 'IEA | Somos' } },
      { path: 'objetivos', component: ObjetivosView, meta: { title: 'IEA | Objetivos' } },
      { path: 'historia', component: HistoriaView, meta: { title: 'IEA | Historia' } },
      { path: 'organigrama', component: OrganigramaView, meta: { title: 'IEA | Organigrama' } },

      { path: 'becas', component: BecasView, meta: { title: 'IEA | Becas' } },
      { path: 'becas/historico', component: HistoricoBecasView, meta: { title: 'IEA | Becas' } },
      { path: 'convocatorias', component: ConvocatoriasView, meta: { title: 'IEA | Convocatorias' } },
      { path: 'adquisiciones', component: ProcesosAdquisicionView, meta: { title: 'IEA | Procesos de Adquisición' } },

      { path: 'participacion-social', component: ParticipacionSocialView, meta: { title: 'IEA | Participación Social' } },
      { path: 'escuela-y-familia', component: EscuelaYFamiliaView, meta: { title: 'IEA | Escuela Y Familia' } },
      { path: 'acoso-escolar', component: AcosoEscolarView, meta: { title: 'IEA | Acoso Escolar' } },
      { path: 'estrategias-aula', component: EstrategiasAulaView, meta: { title: 'IEA | Estartegia en el Aula' } },
      { path: 'carrera-magisterio', component: CarreraMagisterioView, meta: { title: 'IEA | Carrera del Magisterio' } },
      { path: 'IEA/sistema-educativo/convocatorias/promocion-vertical/2025-2026', component: TempLink, meta: { title: 'IEA | Carrera del Magisterio' } }, // 31/07/2025

      { path: 'directorio-telefonico', component: DirectorioTelefonicoView, meta: { title: 'IEA | Directorio Telefónico' } },
      { path: 'servicio-social', component: ServicioSocialView, meta: { title: 'IEA | Servicio Social' } },
      { path: 'incorporacion', component: IncorporacionView, meta: { title: 'IEA | Incorporación' } },

      { path: 'scii', component: SciiView, meta: { title: 'IEA | SCII' } },
      { path: 'manuales', component: ManualesView, meta: { title: 'IEA | Manuales' } },
      { path: 'normatividad', component: NormatividadView, meta: { title: 'IEA | Normatividad' } },
      { path: 'codigo-de-etica-y-conducta', component: CodigoEticaYConductaView, meta: { title: 'IEA | Código de Ética y Conducta' } },
      { path: 'comision-de-seguridad-e-higiene', component: ComisionDeSeguridadEHigieneView, meta: { title: 'IEA | Comision de Seguridad e Higiene' } },
      { path: 'lineamientos', component: LineamientosYPoliticasDeEscuelasView, meta: { title: 'IEA | Lineamientos' } },

      { path: 'informes-SRFT', component: InformesSRFTView, meta: { title: 'IEA | SRFT' } },
      { path: 'reportes-SHCP', component: ReportesSHCPView, meta: { title: 'IEA | SHCP' } },
      { path: 'informes-PAE', component: InformesPAEView, meta: { title: 'IEA | PAE' } },
      { path: 'SED', component: SEDView, meta: { title: 'IEA | SED' } },
      { path: 'titulo-V-LGCG', component: TituloVLGCGView, meta: { title: 'IEA | Título V LGCG' } },
      { path: 'IECR', component: IECRView, meta: { title: 'IEA | Expedientes Clasificados' } },
      { path: 'evaluaciones-externas', component: EvaluacionesExternasView, meta: { title: 'IEA | Evaluaciones Externas' } },
      { path: 'MIR-y-FID', component: MIRyFIDView, meta: { title: 'IEA | MIR y FID' } },
      { path: 'FONE-y-Convenios', component: IndicadoresFONEyConveniosFederalesView, meta: { title: 'IEA | FONE' } },
      { path: 'avisos-de-privacidad', component: AvisosDePrivacidadView, meta: { title: 'IEA | Avisos De Privacidad' } },

      { path: 'escalafon', component: EscalafonView, meta: { title: 'IEA | Escalafón' } },
      { path: 'streaming', component: StreamingView, meta: { title: 'IEA | Streaming' } },
      { path: 'biblioteca-digital', component: BibliotecaDigitalView, meta: { title: 'IEA | Biblioteca Digital' } },
      { path: 'videoteca', component: VideotecaView, meta: { title: 'IEA | Videoteca' } },
      //{ path: 'datos', component: DatosAbiertosView, meta: { title: 'IEA | Datos Abiertos' } },
      { path: 'educatel', component: EducatelView, meta: { title: 'IEA | Educatel' } },
    ]
  },
  { path: '/noticia/:id', component: NoticiaView, meta: { title: 'IEA | Noticias' }},
];

export default routes;