<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Avisos de Privacidad</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Aviso de privacidad</th>
              <th class="text-end">Simplificado</th>
              <th class="text-end">Integral</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of avisosDePrivacidad">
              <td>{{ index + 1 }}</td>
              <td>Aviso de Privacidad de {{ file.name }}</td>
              <td class="text-center" width="50">
                <a :href="file.path.simplified" target="_blank" v-if="file.path.simplified !== ''">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
              <td class="text-center" width="50">
                <a :href="file.path.integral" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Marisol Maldonado Romero',
          department: 'Dirección De Gestión Institucional',
          phone: '449 910 5694 ext. 4244',
          updatedAt: '04/12/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

import avisosDePrivacidadData from '@/assets/json/avisosDePrivacidadData.json';

export default {
  name: "AvisosDePrivacidadView",
  components: {
    RIComponent
  },
  data: () => {
    return {
      avisosDePrivacidadData: avisosDePrivacidadData
    }
  },
  computed: {
    avisosDePrivacidad() {
      return this.avisosDePrivacidadData.reduce((acc, current) => {
        const baseName = current.name.replace(/\s?\(.*?\)/, '');

        let item = acc.find(obj => obj.name === baseName);
        if (!item) {
          item = {
            name: baseName,
            type: current.type,
            path: {
              simplified: "",
              integral: ""
            }
          };
          acc.push(item);
        }

        if (current.name.includes("(Integral)")) {
          item.path.integral = current.path;
        } else if (current.name.includes("(Simplificado)")) {
          item.path.simplified = current.path;
        }

        return acc;
      }, []);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
