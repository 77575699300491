<template>
  <div class="container p-0">
    <div class="row">
      <div class="col-12">
        <!-- Buscador de trámites -->
        <div id="tgea"></div>
      </div>
      <div class="col-12">
        <router-link to="/herramientas-digitales" class="tarjeta bg_dg-6">
          <p class="inferior">¡Nuevo Portal de Herramientas Digitales para la Educación!</p>
        </router-link>
        <router-link to="/becas" class="tarjeta bg_dg-1">
          <p class="inferior">¡Nueva Revista Becas Educativas!</p>
        </router-link>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces relacionados -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces Relacionados</h1>
          <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="https://www.gob.mx/sep" target="_blank" rel="noopener noreferrer" class="enlaces-link">Secretaría de Educación Pública</a>
            </li>
            <li class="enlaces-item">
              <a href="https://miportal.iea.edu.mx/" rel="noopener noreferrer" class="enlaces-link">Mi portal de servicios IEA</a>
            </li>
            <li class="enlaces-item">
              <router-link to="/escalafon" class="enlaces-link">Escalafón</router-link>
            </li>
            <li class="enlaces-item">
              <a href="http://evaluacion.iea.edu.mx" class="enlaces-link">Subdirección de Evaluación</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <!-- Sección de enlaces de interés -->
        <div class="enlacesDependencias">
          <h1 class="titulo">Enlaces de Interés</h1>
          <ul class="enlaces-list">
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-de-Actividades-2024-2025.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario de Actividades, Ciclo Escolar 2024-2025</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-Escolar-2024-2025-BASICA.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 EDUCACIÓN PREESCOLAR, PRIMARIA Y SECUNDARIA</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-Escolar-2024-2025-ESCUELAS-NORMALES.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 EDUCACIÓN NORMAL Y DEMÁS PARA LA FORMACIÓN DE MAESTRAS Y MAESTROS DE EDUCACIÓN BÁSICA</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-Escolar-2024-2025-MEDIA-SUPERIOR-SEMESTRAL.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 EDUCACIÓN MEDIA SUPERIOR/PLAN SEMESTRAL</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-Escolar-2024-2025-MEDIA-SUPERIOR-CUATRIMESTRAL.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 EDUCACIÓN MEDIA SUPERIOR/PLAN CUATRIMESTRAL</a>
            </li>
            <li class="enlaces-item">
              <a href="https://www.iea.gob.mx/IEA/sistema-educativo/Calendario-Escolar-2024-2025-TELEBACHILLERATOS.pdf" target="_blank" rel="noopener noreferrer" class="enlaces-link">Calendario Escolar 2024-2025 TELEBACHILLERATOS COMUNITARIOS</a>
            </li>
          </ul>
        </div>
        <router-link to="/directorio-telefonico" class="sideBtn side-blanco">
          <span class="iconify" data-icon="akar-icons:phone" width="35" height="35" color="#2F328A"></span>
          <p>Directorio telefónico</p>
        </router-link>
        <router-link to="/streaming" class="sideBtn side-aqua">
          <span class="iconify" data-icon="fluent-mdl2:streaming" width="35" height="35" color="white"></span>
          <p>Streaming</p>
        </router-link>
        <router-link to="/biblioteca-digital" class="sideBtn side-aqua">
          <span class="iconify" data-icon="akar-icons:book" width="35" height="35" color="white"></span>
          <p>Biblioteca digital</p>
        </router-link>
        <router-link to="/videoteca" class="sideBtn side-aqua">
          <span class="iconify" data-icon="dashicons:editor-video" width="35" height="35" color="white"></span>
          <p>Videoteca</p>
        </router-link>
        <!-- <router-link to="/datos" class="sideBtn side-aqua">
          <span class="iconify" data-icon="carbon:analytics" width="35" height="35" color="white"></span>
          <p>Datos abiertos</p>
        </router-link> -->
        <router-link to="/educatel" class="sideBtn side-blanco">
          <span class="iconify" data-icon="akar-icons:phone" width="35" height="35" color="#2F328A"></span>
          <p>Educatel</p>
        </router-link>
        <div class="imgCard2 bg_base mb-3" style="position: relative;">
          <p class="subtitulo c-blanco" style="position: absolute; z-index: 1; left: 10px; bottom: 20px;">Reportes de acoso <br> escolar en línea</p> 
          <img src="https://www.iea.gob.mx/img/sistema-educativo/acoso-escolar/acoso-escolar.png" alt="..." class="fondo" style="opacity: 0.3;">  
          <a href="https://acosoescolar.iea.edu.mx/" target="_blank" rel="noopener noreferrer" class="btn btn-ln-blanco">Ir al Sitio</a>
        </div>
        <div class="mb-3">
          <a href="https://www.plataformadetransparencia.org.mx" target="_blank" rel="noopener noreferrer">
            <img src="https://www.aguascalientes.gob.mx/img/dependencias/op1.jpeg" alt="Plataforma Nacional de Transparencia" class="img-fluid" />
          </a>
        </div>
        <div class="mb-3">
          <a href="https://consultapublicamx.plataformadetransparencia.org.mx" target="_blank" rel="noopener noreferrer">
            <img src="https://www.aguascalientes.gob.mx/img/dependencias/op4.jpeg" alt="Obligaciones de Consulta de Información Pública" class="img-fluid" />
          </a>
        </div>
      </div>
      <div class="col-12">
        <!-- Sección de redes sociales -->
        <div id="rgea"></div>
        <div class="redes-button-wrapper">
          <a href="https://www.linkedin.com/company/educacionaguascalientes/about/" target="_blank" rel="noopener noreferrer">
            <div class="fb-button">
              <div class="button-content">
                <span class="iconify" data-icon="mdi:linkedin" width="30" height="30" color="white"></span>
                <p class="red-button-title text-center">LinkedIn</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarIEAComponent",
}
</script>

<style lang="scss" scoped>

</style>