<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Instituto de educación de Aguascalientes</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <SliderComponent :sliderId="1" aspectRatio="5 / 4" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Noticias</h1>
        <!-- <div id="vngea"></div> -->
        <NoticiasComponent />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'David Alejandro López Rubalcava',
          department: 'Coordinación de Comunicación Social',
          phone: '449 910 5600 ext. 4372',
          updatedAt: null
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import SliderComponent from '@/components/SliderComponent.vue';
import NoticiasComponent from '@/pages/www/components/NoticiasComponent.vue';

export default {
  name: "PrincipalView",
  components: {
    RIComponent,
    SliderComponent,
    NoticiasComponent,
  },
  // mounted() {
  //   gea.iwin(() => {
  //     generic.inew(document.getElementsByTagName("body")[0].dataset.sigla, 'vngea');
  //   });
  // },
}
</script>

<style lang="scss" scoped>

</style>