<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Coordinación de Becas y Financiamiento Educativo</h1>
      </div>
    </div>

    <div class="row" v-if="isValidity('02/02/2024 - 13/09/2024')">
      <div class="col">
        <h2 class="subtitulo">¿Ya tienes tu expediente digital?</h2>
        <div class="banner-body-left bg_6">
          <img src="https://www.iea.gob.mx/img/convocatorias-y-becas/becas/img-banner.webp" alt="..." class="img-fluid"/>
          <div class="baninfo-wrapper">
            <h1>Expediente Digital</h1>
            <p>Papás y mamás, para participar en el proceso de Becas es necesario tener el Expediente Digital de su hijo o hija</p>
            <div class="d-flex flex-column">
              <a href="https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light mb-3" style="position: static;">
                Crear Expediente Digital
              </a>
              <a href="https://youtu.be/ECj_VoBAzUo?si=kM26tlXDcYfX0tos" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light mb-3" style="position: static;">
                Tutorial para crear Expediente Digital
              </a>
              <a href="https://youtu.be/MPGRcrDsa08?si=PsqlMxtApO78PQgz" target="_blank" rel="noopener noreferrer" class="btn btn-principal-light" style="position: static;">
                Tutorial para solicitar una beca a través del Expediente Digital
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm bg_dg-5 mb-3">
          <div class="item1">
            <p class="titulo">Calendario de Pago de Becas</p>
          </div>
          <div class="item2">
            <p class="contenido">Pago de Becas Alumnos Rezagados</p>
          </div>
          <div class="item3">
            <button type="button" class="btn btn-principal-light" data-bs-toggle="modal" data-bs-target="#modal">
              Ver Información
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="scholarshipCalls.length > 0">
      <div class="col">
        <h2 class="subtitulo">Convocatorias y Resultados de Becas</h2>
        <div class="alert alert-primary d-flex align-items-center" role="alert" v-if="isValidity('02/02/2024 - 13/09/2024')">
          <span class="iconify" data-icon="ep:info-filled" width="75" height="75"></span>
          <div>
            Estimado solicitante, las becas que tienen habilitada la opción <b>Registrar Solicitud</b> requieren que tengas creado y actualizado tu <b>Expediente Digital</b>. Para crear tu Expediente Digital, visita:
            <a href="https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx" target="_blank" rel="noopener noreferrer">Expediente Digital</a>.
          </div>
        </div>
        <ConvocatoriasComponent :calls="scholarshipCalls" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Revista Becas Educativas</h2>
      </div>
      <div class="col-12 col-md-6 col-lg-3 text-center" v-for="publication of publications">
        <a :href="publication.path" target="_blank">
          <img :src="publication.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{ publication.name }}</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="banner_sm" style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Coordinación de Becas y Financiamiento Educativo</p>
          </div>
          <div class="item2">
            <p class="contenido">Av. Manuel Gomez Morin S/N, Ferronales, 20180 <br> Tel. 449 910 5694</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alberto Robles Núñez',
          department: 'Coordinación de Becas y Financiamiento Educativo',
          phone: '449 910 5694',
          updatedAt: '11/12/2024'
        }" />
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="stCard cartaInfo">
            <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_informes.svg" alt="icono" class="icono" />
            <p class="titulo">Pago de Becas Alumnos Rezagados</p>
            <div class="descripcion">
              <p>
                La Coordinación de Becas y Financiamiento Educativo informa a los alumnos
                del municipio capital y de los municipios del interior que no han acudido
                a recoger su beca, lo podrán hacer únicamente en la siguiente ubicación,
                fecha y hora:
              </p>
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Lugar</th>
                    <th class="text-center">Ubicación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      Martes 17 de diciembre de 2024
                    </td>
                    <td>9:00 a.m.</td>
                    <td>
                      Plaza del mueble (Paseo de los Chicahuales, Col. Benigno Chávez, Jesús María, Ags)
                    </td>
                    <td class="text-center">
                      <a href="https://maps.app.goo.gl/zcYraWMtJWDjg3m58" target="_blank">
                        <span class="iconify" data-icon="mdi:google-maps" width="35" height="35" color="red"></span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-start">
                <p><b>Becas:</b></p>
                <ul>
                  <li>Beca Fortalecimiento a la Permanencia Escolar (Educación Primaria y Secundaria).</li>
                  <li>Beca Educación Especial y Barreras para el Aprendizaje (Educación Primaria y Secundaria).</li>
                  <li>Estímulo a la Excelencia Educativa (Educación Primaria y Secundaria).</li>
                  <li>Estímulo a la Excelencia Educativa (Educación Media Superior).</li>
                  <li>Beca Tu Futuro es Gigante (Educación Superior).</li>
                  <li>Beca IEA‑SNTE (Educación Primaria, Secundaria, Media Superior y Superior).</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from '../../components/ConvocatoriasComponent.vue';
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "BecaslView",
  components: {
    ConvocatoriasComponent,
    RIComponent
  },
  data() {
    return {
      scholarshipCalls: [
        // {
        //   title: "Beca Académica - Educación Inicial y Preescolar (Ciclo Escolar 2024-2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Educación Inicial y Preescolar (Ciclo Escolar 2024-2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        // {
        //   title: "Beca Académica - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        {
          title: "Beca Académica - Educación Básica (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
          links: [
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Académica - Educación Básica (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        // {
        //   title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        // {
        //   title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025)",
        //   type: "Convocatoria de Beca",
        //   icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025).pdf",
        //       validity: "02/09/2024 - 20/09/2024"
        //     }
        //   ],
        //   validity: "02/09/2024 - 20/09/2024"
        // },
        {
          title: "Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024, Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_listaviaje.svg",
          links: [
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Académica - Formación para el Trabajo, Media Superior y Superior (Segundo Semestre 2024, Tercer Cuatrimestre 2024 y Primer Cuatrimestre 2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_familiaDos.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Educación Especial y Barreras para el Aprendizaje - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_cuidado.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Fortalecimiento a la Permanencia Escolar - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_dinero.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca IEA‑SNTE - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_dinero.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024).pdf",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca IEA‑SNTE - Educación Media Superior y Superior (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Beca Tu Futuro es Gigante - Educación Superior(Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_derechos.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Beca Tu Futuro es Gigante - Educación Superior (Segundo Semestre 2024).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Beca Tu Futuro es Gigante - Educación Superior (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diploma.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Estímulo a la Excelencia Educativa - Educación Primaria y Secundaria (Ciclo Escolar 2024-2025).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        },
        {
          title: "Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024)",
          type: "Convocatoria de Beca",
          icon: "https://eservicios2.aguascalientes.gob.mx/portalgea/iconos/i_diploma.svg",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/CVT-Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024).pdf",
              validity: "02/09/2024 - 13/09/2024"
            },
            {
              description: "Registrar Solicitud",
              link: "https://expedientedigital.aguascalientes.gob.mx/expediente/ui/ciudadano/Inicio.aspx",
              validity: "09/09/2024 - 13/09/2024"
            },
            {
              description: "Ver Resultados",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/RES-Estímulo a la Excelencia Educativa - Educación Media (Segundo Semestre 2024).pdf",
              validity: "01/11/2024 - 30/11/2024"
            }
          ],
          validity: "01/11/2024 - 30/11/2024"
        }
      ],
      publications: [
        {
          name: "Revista Becas Educativas 1° Edición",
          type: "file",
          path: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/becas/revista/01-Revista Becas Educativas.pdf",
          image: "https://www.iea.gob.mx/img/convocatorias-y-becas/becas/revista/01-Revista Becas Educativas.webp"
        }
      ]
    }
  },
  mounted() {
    gea.iwin(() => {
      const myModal = bootstrap.Modal.getOrCreateInstance("#modal");
      myModal.show()
    });
  },
  updated() {
    gea.iwin(() => {
      const myModal = bootstrap.Modal.getOrCreateInstance("#modal");
      myModal.show()
    });
  },
  methods: {
    isValidity(validity) {
      let today = new Date();

      let startDate = validity.split("-")[0].trim();

      let startDateParts = startDate.split("/");
      let startDay = parseInt(startDateParts[0], 10);
      let startMonth = parseInt(startDateParts[1], 10) - 1;
      let startYear = parseInt(startDateParts[2], 10);

      let endDate = validity.split("-")[1].trim();

      let endDateParts = endDate.split("/");
      let endDay = parseInt(endDateParts[0], 10);
      let endMonth = parseInt(endDateParts[1], 10) - 1;
      let endYear = parseInt(endDateParts[2], 10);

      startDate = new Date(startYear, startMonth, startDay);
      endDate = new Date(endYear, endMonth, endDay, 23, 59, 59, 0);

      if (today >= startDate && today <= endDate) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
