<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Buscador de trámites -->
        <div id="tgea"></div>
      </div>
      <div class="col-12">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSefUES5_wW-azUMC296Zxh-62nyiWAzf2H5DbsF8Ir4CSh2uQ/viewform?pli=1" target="_blank" class="sideBtn bg_dg-6">
          <span class="iconify" data-icon="mdi:mailbox" width="50" height="50" color="white"></span>
          <p class="c_blanco">Buzón virtual de quejas y sugerencias</p>
        </a>
      </div>
      <div class="col-12">
        <MailboxComponent toEmail="informes.educacionsuperior@iea.edu.mx" subject="BUZÓN | DIRECCIÓN DE EDUCACIÓN SUPERIOR" />
      </div>
      <div class="col-12">
        <!-- Sección de redes sociales -->
        <h1 class="titulo">Redes Sociales</h1>
        <div class="redes-button-wrapper">
          <a href="https://www.facebook.com/profile.php?id=61557037982870" target="_blank">
            <div class="fb-button">
              <div class="button-content">
                <span class="iconify" data-icon="uil:facebook-f" width="30" height="30" color="white"></span>
                <p class="red-button-title text-center">Facebook</p>
              </div>
            </div>
          </a>
          <a href="https://www.youtube.com/@ieaoficial9366" target="_blank">
            <div class="youtube-button">
              <div class="button-content">
                <img src="https://www.aguascalientes.gob.mx/img/Iconografia/youtube.svg" alt="youtube" class="text-center">
                <p class="red-button-title">Youtube</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MailboxComponent from "@/components/MailboxComponent.vue";

export default {
  name: "SidebarDESComponent",
  components: {
    MailboxComponent
  }
};
</script>

<style lang="scss" scoped>

</style>
